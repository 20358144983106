import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import dayjs from 'dayjs';

import { useGetSafetyRuleList } from 'pages/Attendee/AttendeeSafetyRuleList/queries/useGetSafetyRuleList';
import { usePatchRecordingQuery } from './queries/usePatchRecordingQuery';
import { useBugReportQuery } from './queries/useBugReportQuery';
import { useCompleteTbmQuery } from './queries/useCompleteTbmQuery';
import useSTT from 'hooks/useSTT';
import useWindowDemensions from 'hooks/useWindowDemensions';
import { usePrompt } from 'hooks/usePrompt';

import ErrorModal from './component/ErrorModal';
import SpeechBubble from './component/SpeechBubble';
import getScreenHeight from 'util/getScreenHeight';
import { flex } from 'styles/flex';
import { font } from 'styles/fonts';
import { fitImg } from 'styles/mixins';
import useVoiceMark from 'hooks/useVoiceMark';
import useVoiceMarkQuery from 'hooks/useVoiceMarkQuery';
import usePageVisibility from 'hooks/usePageVisibility';

type ErrorType = 'BUG_REPORT' | 'EXIT';

const errorModalText = {
  BUG_REPORT: {
    quit: '돌아가기',
    confirm: '확인',
    text: '음성인식에 오류가 있으나 \n낭독한 내용은 모두 전송되었습니다.',
    boldText: '화면의 안전수칙을 \n정확하게 인지하셨나요?',
  },
  EXIT: {
    quit: '취소',
    confirm: '나가기',
    text: '',
    boldText: '이 페이지를 나가시면 \n낭독하던 내용이 사라집니다. \n정말로 나가시겠습니까?',
  },
};

export default function RecordingScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const { voiceMarkData, clearAudioBuffer, getVoiceMarkQR } = useVoiceMark();
  const { isError: voiceMarkError } = useVoiceMarkQuery();
  const isVisible = usePageVisibility();

  const { showPrompt, confirmNavigation, cancelNavigation } = usePrompt(true);

  const targetReadRule = useRef('');
  const targetPositionRef = useRef<HTMLDivElement>();

  const params = useRef(new URLSearchParams(location.search));
  const dateRef = useRef<string>(dayjs(params.current.get('date')).format('YYYY-MM-DD'));
  const tbmIdRef = useRef<string>(params.current.get('tbm_id'));
  const attendeeIdRef = useRef<string>(params.current.get('attendeeId'));

  const targetParamRef = useRef(location.search.slice(0, location.search.indexOf('name') - 1));

  const [ruleNo, setRuleNo] = useState(1);
  const [errorCount, setErrorCount] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [prevRead, setPrevRead] = useState('');
  const [completedStatus, setCompletedStatus] = useState(false);
  const [errorType, setErrorType] = useState<ErrorType>('EXIT');
  const { mutate: patchRecording } = usePatchRecordingQuery();
  const { mutate: patchBugReport, isSuccess: bugReportingSuccess } = useBugReportQuery();
  const { mutate: completeTbm, isSuccess: isCompletedSuccess } = useCompleteTbmQuery();

  const { width } = useWindowDemensions();

  const { data } = useGetSafetyRuleList(+attendeeIdRef.current, targetParamRef.current);
  const safetyRuleList = data?.safetyRuleList;

  const { quit, confirm, text, boldText } = errorModalText[errorType];

  const {
    openSocket,
    closeSocket,
    isSocketOpen,
    readRule,
    unReadRule,
    isRecordingCompleted,
    audioUrl,
    error: sttError,
  } = useSTT();

  const ruleNumbers = safetyRuleList?.length;

  const { id, completed, script } = Object(safetyRuleList && safetyRuleList[ruleNo - 1]);

  const toggleModal = () => {
    setShowErrorModal((prev) => !prev);
  };

  const handleRecordingResult = () => {
    if (completed) {
      return (
        <SpeechBubble>
          <p>낭독을 완료하였습니다.</p>
        </SpeechBubble>
      );
    }

    if (completedStatus) {
      return;
    }

    if (errorCount && !isSocketOpen) {
      return (
        <SpeechBubble>
          <p>올바로 인식되지 않았습니다.</p>
          <p>버튼을 누르고 지정된 부분부터</p>
          <p>다시 읽어주세요.</p>
        </SpeechBubble>
      );
    }

    if (!isSocketOpen) {
      return (
        <SpeechBubble>
          <p>버튼을 누른뒤</p>
          <p>자연스럽게 읽어주세요.</p>
        </SpeechBubble>
      );
    }
  };

  const handleRecordingIcon = () => {
    if (completed) return 'icons/ic-check.png';
    if (isSocketOpen) return 'icons/ic-reset.png';
    return 'icons/ic-mic.png';
  };

  const handleRuleNo = (addition:number) => {
    if (isSocketOpen) {
      closeSocket();
    }

    setRuleNo(ruleNo + addition);
    setPrevRead('');
    targetReadRule.current = '';
  };

  const handleBugReportBtn = () => {
    setErrorType('BUG_REPORT');
    toggleModal();
  };

  const handleBugReport = async () => {
    patchBugReport({
      ruleId: id,
      transcribed: script,
      audioUrl,
      attendeeId: +attendeeIdRef.current,
      tbmId: tbmIdRef.current,
      date: dateRef.current,
    });
  };

  const handleSTT = async () => {
    const updated = prevRead ? unReadRule : script;
    if (!isSocketOpen) {
      openSocket(updated, +attendeeIdRef.current);
    } else {
      await closeSocket();
      await openSocket(updated, +attendeeIdRef.current);
    }
  };

  const handleTargetRule = () => {
    if (isSocketOpen) {
      return `${prevRead} ${readRule}`;
    }
    return prevRead;
  };

  useEffect(() => {
    if (!isRecordingCompleted) return;

    setCompletedStatus(true);

    patchRecording({
      ruleId: id,
      transcribed: script,
      audioUrl,
      attendeeId: +attendeeIdRef.current,
      tbmId: tbmIdRef.current,
      date: dateRef.current,
    });

    setPrevRead((prev) => `${prev} ${readRule}`);

    // generate QR code for the combined audio
    if (ruleNo === safetyRuleList?.length) {
      getVoiceMarkQR(+attendeeIdRef.current);
    }
  }, [isRecordingCompleted]);

  useEffect(() => {
    if (errorCount) {
      setErrorCount(0);
    }
    targetReadRule.current = '';
    setCompletedStatus(false);
  }, [ruleNo]);

  useEffect(() => {
    if (sttError) {
      setErrorCount((prev) => prev + 1);
      setPrevRead((prev) => `${prev} ${readRule}`);
    }
  }, [sttError]);

  useEffect(() => {
    if (!bugReportingSuccess) return;

    // completeTbm when last rule was reported as bug
    if (ruleNo === safetyRuleList?.length) {
      getVoiceMarkQR(+attendeeIdRef.current);
    }

    setRuleNo((prev) => prev + 1);
  }, [bugReportingSuccess]);

  useEffect(() => {
    if (!readRule) return;

      targetReadRule.current = readRule;

      if (!targetPositionRef.current) return;
      targetPositionRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [readRule]);

  useEffect(() => {
    setShowErrorModal(showPrompt);
    if (showPrompt) {
      setErrorType('EXIT');
    }
  }, [showPrompt]);

  useEffect(() => {
    getScreenHeight();
    window.addEventListener('resize', getScreenHeight);
  }, []);

  useEffect(() => {
    if (!voiceMarkData && !voiceMarkError) return;

    completeTbm({
      tbmId: tbmIdRef.current,
      isComplete: true,
      attendeeId: +attendeeIdRef.current,
      date: dateRef.current,
      qrPath: voiceMarkData?.qrcodePath || '',
    });
  }, [voiceMarkError, voiceMarkData]);

  useEffect(() => {
    if (!isCompletedSuccess) return;
      clearAudioBuffer();
      confirmNavigation();
      navigate(`/attendee/${attendeeIdRef.current}/tbm/${tbmIdRef.current}?&date=${dateRef.current}`);
      alert('오늘의 안전수칙을 모두 낭독했습니다');
  }, [isCompletedSuccess]);

  useEffect(() => {
    if (!isVisible && isSocketOpen){
      closeSocket();
      setPrevRead((prev) => `${prev} ${readRule}`);
      setErrorCount((prev) => prev + 1);
    }
  }, [isVisible]);

  return (
    <Container>
      <ErrorModal
        closeModal={errorType === 'EXIT' ? cancelNavigation : toggleModal}
        confirmBtnText={confirm}
        handleRequest={errorType === 'EXIT' ? confirmNavigation : handleBugReport}
        modalBoldText={boldText}
        modalText={text}
        modalVisible={showErrorModal}
        quitBtnText={quit}
      />
      {!safetyRuleList?.length ? (
        <div>낭독할 안전수칙이 없습니다.</div>
      ) : (
        <RecordingContainer>
          <RecordingWrap>
            <ProgressBarWrap>
              진행률
              <ProgressBar width={width * 0.4}>
                <InnerBar width={((width * 0.4) / (ruleNumbers || 1)) * ruleNo} />
              </ProgressBar>
              {ruleNo}
              <PrgressTextTotal>{`/${safetyRuleList?.length}`}</PrgressTextTotal>
            </ProgressBarWrap>
            <StatusWrapper>
              {errorCount >= 2 && !completed && !isSocketOpen && (
              <BugReportBtn onClick={handleBugReportBtn}>
                <img alt="question" src="icons/ic-question.png" />
              </BugReportBtn>
              )}
            </StatusWrapper>
            <ContextWrap>
              {targetReadRule.current || errorCount ? (
                <ParsedText>
                  {handleTargetRule()}
                  {!isSocketOpen && !!errorCount && !!unReadRule && (
                  <PointerWrap>
                    <img alt="unread-part-indigator-icon" src="icons/ic-pointer.png" />
                  </PointerWrap>
                  )}
                  <InvisibleText ref={targetPositionRef} />
                  <UnReadText active={isSocketOpen}>
                    {' '}
                    {unReadRule}
                  </UnReadText>
                </ParsedText>
              ) : (
                <ContextText active={completed}>{script}</ContextText>
              )}
            </ContextWrap>
          </RecordingWrap>
          {handleRecordingResult()}
          <BottomIconWrap>
            <ArrowBtnWrap
              disabled={ruleNo === 1}
              onClick={() => handleRuleNo(-1)}
            >
              <ArrowIcon
                active={ruleNo !== 1}
                alt="previous-safety-rule"
                src="icons/ic-left-arrow.png"
              />
            </ArrowBtnWrap>
            <STTIconWrap disabled={completed} onClick={() => handleSTT()}>
              <img alt="recording-result" src={handleRecordingIcon()} />
            </STTIconWrap>
            <ArrowBtnWrap
              disabled={
                (!!safetyRuleList && !safetyRuleList[ruleNo - 1].completed) ||
                ruleNo === safetyRuleList?.length
              }
              onClick={() => handleRuleNo(1)}
            >
              <ArrowIcon
                active={(!!safetyRuleList && !!safetyRuleList[ruleNo - 1].completed) ||
                 ruleNo - 1 === safetyRuleList?.length}
                alt="next-safety-rule"
                src="icons/ic-right-arrow.png"
              />
            </ArrowBtnWrap>
          </BottomIconWrap>
        </RecordingContainer>
      )}
    </Container>
  );
}

interface IActive {
  active: boolean;
}

interface IWidth {
  width: number;
}

const Container = styled.div`
  ${flex('space-between', 'center', 'column')};
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background: #fafafa;
`;

const RecordingContainer = styled.div`
  ${flex('space-between', 'center', 'column')};
  flex: 1;
  padding: 23px 25px;
  align-items: center;
  position: relative;
`;

const RecordingWrap = styled.div`
  ${flex('center', 'center', 'column')};
  padding: 27px 20px;
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  max-width: 100vw;
`;

const ProgressBarWrap = styled.div`
  ${flex('space-between', 'center')};
  margin-bottom: 6px;
  ${font(14, 400, 21)}
`;

const PrgressTextTotal = styled.div`
  color: #999999;
`;

const ProgressBar = styled.div<IWidth>`
  width: ${(props) => props.width}px;
  height: 10px;
  margin: 0 20px;
  background-color: #fafafa;
  border-radius: 8px;
  position: relative;
`;

const InnerBar = styled(ProgressBar)<IWidth>`
  background-color: #15aedb;
  height: 6px;
  position: absolute;
  top: 2px;
  margin: 0 2px;
  width: ${(props) => props.width}px;
`;

const ContextWrap = styled.div`
  ${flex('space-between', '', 'column')};
  background-color: #fff;
  border-radius: 13px;
  width: 100%;
  min-height: 200px;
  height: 50vh;
  ${font(30, 700, 52)};
  overflow-y: scroll;
`;

const InvisibleText = styled.div`
  width: 0.1px;
  height: 0.1px;
  display: inline;
`;

const ParsedText = styled.div`
  width: 100%;
  color: #15aedb;
  display: inline;
  word-break: keep-all;
`;

const UnReadText = styled.div<IActive>`
  width: 100%;
  display: inline;
  word-break: keep-all;
  color: ${(props) => (props.active ? '#000000' : '#E70C0C')};
`;

const ContextText = styled.div<IActive>`
  color: ${(props) => (props.active ? '#15AEDB' : '#000000')};
  word-break: keep-all;
`;

const PointerWrap = styled.div`
  ${flex('flex-start', 'flex-start', 'row')}
  display: inline;
  height: 100%;
  position: relative;

  img {
    position: absolute;
    top: -12px;
    left: 6px;
    height: 12px;
    width: 12px;
  }
`;

const StatusWrapper = styled.div`
  ${flex('flex-end', 'center')};
  width: 100%;
  height: 28px;
`;

const BugReportBtn = styled.button`
  ${flex('center', 'center', 'row')};
  height: 28px;
  ${fitImg}
`;

const STTIconWrap = styled.button`
  ${fitImg}
  ${flex('center', 'center')}
  width: 72px;
  height: 72px;
  background: #15aedb;
  border-radius: 50px;
  img {
    width: 40px;
    height: 40px;
  }
`;

const BottomIconWrap = styled.div`
  width: 100%;
  ${flex('space-between', 'center', 'row')}
`;

const ArrowBtnWrap = styled.button``;

const ArrowIcon = styled.img<{ active: boolean }>`
  width: 18px;
  height: 36px;
  ${({ active }) =>
    !active &&
    css`
      opacity: 0;
    `}
`;
