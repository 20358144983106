import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import axiosClient from 'libs/axios/axios';
import { useSetRecoilState } from 'recoil';

import { snakeToCamel } from 'util/snakeToCamel';
import { tbmInfoState } from 'pages/recoil/TbmManagement';
import { IGetTbmInfoType } from 'types/tbmManagement';

const fetchTemplateDetail = async (templateId) => {
  const { data } = await axiosClient.get(`/tbm/template/detail/${templateId}`);

  return snakeToCamel(data);
};

export const useGetTemplateDetailQuery = (templateId: string, isEdit: boolean, date: string) => {
  const setTbmInfo = useSetRecoilState(tbmInfoState);

  return useQuery<IGetTbmInfoType, AxiosError>(['templateDetail', 'edit'], () => fetchTemplateDetail(templateId), {
    // useQuery is not triggered when creating and editing tbm.
    enabled: templateId !== 'new' && !isEdit,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const { emergencyProcedures, safetyMeasureList, operationId } = data;
      const safetyMeasureArray = safetyMeasureList.map((safetyMeasure) => ({
        id: safetyMeasure.id,
        safetyMeasureName: safetyMeasure.name,
        safetyMeasureType: '',
      }));
      setTbmInfo({
        ...data,
        emergencyProcedureList: emergencyProcedures,
        safetyMeasureList: safetyMeasureArray,
        operationType: operationId,
        attendeeList: [],
        editParticipantList: data.participantList,
        editAttendeeList: [],
        date,
      });
    },
    onError: (error) => {
      console.error('get Tbm templateDetail error', error);
    },
  });
};
export default useGetTemplateDetailQuery;
