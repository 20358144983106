import { useState } from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';

import ButtonModal from 'pages/components/ButtonModal';

import { useDeleteTemplateQuery } from 'pages/CreateTBM/queries/useDeleteTemplateQuery';
import { useGetTemplateQuery } from 'pages/CreateTBM/queries/useGetTemplateQuery';
import { selectedDateState } from 'pages/recoil/createTBM';

import { flex } from 'styles/flex';
import { font } from 'styles/fonts';

interface ITemplateModalPropTypes {
  isModalOpen: boolean;
  handleModal: () => void;
  postMessageToRN: (selectedDate: string, id: number | null, idType: string) => void;
}

export default function TemplateModal({ isModalOpen, handleModal, postMessageToRN }: ITemplateModalPropTypes) {
  const { data: templateList } = useGetTemplateQuery();
  const { mutate: deleteTemplate } = useDeleteTemplateQuery();
  const selectedDate = useRecoilValue(selectedDateState);
  const [selectedTemplateId, setSelectedTemplateId] = useState<number>();

  const handleItemClick = (id: number) => {
    setSelectedTemplateId(id);
  };

  const handleDeleteBtnClick = () => {
    if (!selectedTemplateId) return;

    deleteTemplate(selectedTemplateId);
    setSelectedTemplateId(null);
  };

  const handleUseTemplateBtnClick = () => {
    if (!selectedTemplateId) return;

    postMessageToRN(selectedDate.format('YYYY-MM-DD'), selectedTemplateId, 'template');
    setSelectedTemplateId(null);
  };

  return (
    <ButtonModal
      buttons={[
        { type: 'Deleted', name: '삭제', action: handleDeleteBtnClick },
        { type: 'Confirm', name: '불러오기', action: handleUseTemplateBtnClick },
      ]}
      isOpen={isModalOpen}
      title="템플릿 불러오기"
      onClose={() => handleModal()}
    >
      <TemplateList>
        {templateList?.map(({ id, title }) => (
          <Template key={`template-${id}`} isActive={selectedTemplateId === id} onClick={() => handleItemClick(id)}>
            {title}
          </Template>
        ))}
      </TemplateList>
    </ButtonModal>
  );
}

interface IActive {
  isActive: boolean;
}

const TemplateList = styled.div`
  width: 100%;
  margin: 30px 0;
  max-height: 200px;
  overflow: scroll;
  scrollbar-width: none;

  p {
    ${font(14, 400, 27)};
    color: #000000;
    text-align: center;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Template = styled.div<IActive>`
  ${font(12, 400, 18)};
  ${flex('initial', 'flex-start', 'column')};
  background: ${({ isActive }) => (isActive ? '#aaecff' : '#DDDDDD')};
  border-radius: 4px;
  color: #000000;
  padding: 9px 12px;
  width: 100%;
  height: fit-content;
  margin-bottom: 10px;

  :last-child {
    margin-bottom: 0;
  }
`;
