import { useState } from 'react';
import styled, { css } from 'styled-components';

import RecentParticipantList from './components/RecentParticipantList';
import ParticipantList from './components/ParticipantList';

import { flex } from 'styles/flex';
import { font } from 'styles/fonts';
import { IAttendee, IParticipant, IRecentParticipant } from 'types/tbmManagement';
import AttendeeList from './components/AttendeeList';

interface IParticipantSelctorProps {
  data: {
    participantList: IParticipant[];
    attendeeList: IAttendee[];
    editAttendeeList: IAttendee[];
    editParticipantList: IParticipant[];
  };
  query: { participantListQuery: any; recentListQuery: any; attendeeListQuery: any };
  request: {
    addAttendee: Function;
    deleteAttendee: Function;
  };
  onClose: Function;
  onConfirm: Function;
  isEdit: boolean;
}

type TabMenuList = 'recentList' | 'participantList' | 'attendeeList';

export default function ParticipantSelctor({
  data: { participantList, attendeeList, editAttendeeList, editParticipantList },
  query,
  onClose,
  onConfirm,
  request,
  isEdit,
}: IParticipantSelctorProps) {
  const [tabMenu, setTabMenu] = useState<TabMenuList>('recentList');
  const [targetParticipantList, setTargetParticipantList] = useState<IParticipant[]>(
    isEdit ? editParticipantList : participantList,
  );
  const [targetRecentData, setTargetRecentData] = useState<IRecentParticipant>({ tbmId: 0, date: '', participant: [] });
  const [targetAttendeeList, setTargetAttendeeList] = useState<IAttendee[]>(isEdit ? editAttendeeList : attendeeList);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const { participantListQuery, recentListQuery, attendeeListQuery } = query;
  const { deleteAttendee, addAttendee } = request;

  const handleParticipantClick = (target: IParticipant) => {
    const hasId = !!targetParticipantList.find((el) => el.id === target.id);
    setTargetParticipantList((prev) => (hasId ? prev.filter((el) => el.id !== target.id) : [...prev, { ...target }]));
  };

  const handleRecentDataClick = (target: IRecentParticipant) => {
    setTargetRecentData({ ...target });
  };

  const handleAttendeeClick = (target: IAttendee) => {
    const hasId = !!targetAttendeeList.find((el) => el.id === target.id);
    setTargetAttendeeList((prev) => (hasId ? prev.filter((el) => el.id !== target.id) : [...prev, { ...target }]));
  };

  const handleConfirmBtnClick = () => {
    if (isEdit) {
      onConfirm((prev: any) => ({
        ...prev,
        editParticipantList: tabMenu === 'recentList' ? targetRecentData.participant : targetParticipantList,
        editAttendeeList: targetAttendeeList,
      }));
      onClose();
      return;
    }
    onConfirm((prev: any) => ({
      ...prev,
      participantList: tabMenu === 'recentList' ? targetRecentData.participant : targetParticipantList,
      attendeeList: targetAttendeeList,
    }));
    onClose();
  };

  const handleDeleteBtnClick = () => {
    if (targetAttendeeList.length < 1) return;
    setShowDeleteAlert(true);
  };

  const handleConfirmDeleteBtnClick = () => {
    const idList = targetAttendeeList.map((el) => el.id);
    deleteAttendee(idList);
    setTargetAttendeeList([]);
    setShowDeleteAlert(false);
  };

  const handleCancelBtnClick = () => {
    setShowDeleteAlert(false);
  };

  const CONTENTS = {
    recentList: (
      <RecentParticipantList
        handleItemClick={handleRecentDataClick}
        query={recentListQuery}
        target={targetRecentData}
      />
    ),
    participantList: (
      <ParticipantList
        handleItemClick={handleParticipantClick}
        query={participantListQuery}
        target={targetParticipantList}
      />
    ),
    attendeeList: (
      <AttendeeList
        addAttendee={addAttendee}
        handleItemClick={handleAttendeeClick}
        query={attendeeListQuery}
        showDeleteAlert={showDeleteAlert}
        target={targetAttendeeList}
      />
    ),
  };

  return (
    <Container>
      <Header>
        <HeaderText isActive={tabMenu === 'recentList'} onClick={() => setTabMenu('recentList')}>
          최근
        </HeaderText>
        <Line />
        <HeaderText isActive={tabMenu === 'participantList'} onClick={() => setTabMenu('participantList')}>
          목록
        </HeaderText>
        <Line />
        <HeaderText isActive={tabMenu === 'attendeeList'} onClick={() => setTabMenu('attendeeList')}>
          비회원
        </HeaderText>
      </Header>
      <ContentWrap>{CONTENTS[tabMenu]}</ContentWrap>
      <BtnWrap>
        {tabMenu !== 'attendeeList' && <ConfirmBtn onClick={() => handleConfirmBtnClick()}>확인</ConfirmBtn>}
        {tabMenu === 'attendeeList' && !showDeleteAlert && (
          <>
            <AttendeeTabDeleteBtn onClick={() => handleDeleteBtnClick()}>삭제</AttendeeTabDeleteBtn>
            <AttendeeTabConfirmBtn onClick={() => handleConfirmBtnClick()}>확인</AttendeeTabConfirmBtn>
          </>
        )}
        {tabMenu === 'attendeeList' && showDeleteAlert && (
          <>
            <AttendeeTabDeleteBtn onClick={() => handleConfirmDeleteBtnClick()}>삭제</AttendeeTabDeleteBtn>
            <AttendeeTabCancelBtn onClick={() => handleCancelBtnClick()}>취소</AttendeeTabCancelBtn>
          </>
        )}
      </BtnWrap>
    </Container>
  );
}

interface IActive {
  isActive: boolean;
}

const Container = styled.div`
  ${flex('', '', 'column')}
`;

const Header = styled.div`
  ${flex('space-evenly', 'center')}
  margin : 30px 0 28px;
`;

const HeaderText = styled.p<IActive>`
  ${font(14, 400, 14)};
  color: #000000;
  ${({ isActive }) =>
    isActive &&
    css`
      color: #15aedb;
      font-weight: 700;
    `}
`;

const Line = styled.div`
  width: 1px;
  height: 12px;
  background: #d9d9d9;
`;

const BtnWrap = styled.div`
  margin-top: 26px;
  display: flex;
  flex: 1;
`;

const ConfirmBtn = styled.button`
  ${font(18, 500, 17)}
  ${flex('center', 'center')}
  height: 52px;
  flex: 1;
  border-radius: 5px;
  text-align: center;
  background: #15aedb;
  color: #ffffff;
`;

const AttendeeTabConfirmBtn = styled.button`
  ${font(18, 500, 17)}
  ${flex('center', 'center')}
  height: 52px;
  flex: 1;
  border-radius: 5px;
  text-align: center;
  background: #15aedb;
  color: #ffffff;
  margin-left: 10px;
`;

const AttendeeTabCancelBtn = styled(AttendeeTabConfirmBtn)`
  background-color: #999999;
`;

const AttendeeTabDeleteBtn = styled(AttendeeTabConfirmBtn)`
  background-color: #ea3313;
  margin: 0;
`;

const ContentWrap = styled.div`
  height: 377px;
`;
