import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { font } from 'styles/fonts';
import { flex } from 'styles/flex';

type SpeechBubbleProps = {
  children : ReactNode;
};

function SpeechBubble({
  children,
}: SpeechBubbleProps) {
  return (
    <SpeechBubbleView>
      <ImageBackground>
        {children}
      </ImageBackground>
    </SpeechBubbleView>
  );
}

export default SpeechBubble;

const SpeechBubbleView = styled.div`
  ${flex('center', 'center', 'column')}
  z-index: 1;
  position: absolute;
  bottom: 85px;
  min-height: 200px;
`;

const ImageBackground = styled.div`
  background-image: url('icons/ic-speech-bubble.png') ;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  min-height: 150px;
  width: 300px;
  ${font(20, 700, 30)}
  text-align: center;
  padding-bottom: 10px;
  ${flex('center', 'center', 'column')}
`;
