import { useState, useEffect } from 'react';

function getBrowserVisibilityProp() {
    if (typeof document.hidden !== 'undefined') {
      return 'visibilitychange';
    }
  }

function getBrowserDocumentHiddenProp() {
    if (typeof document.hidden !== 'undefined') {
      return 'hidden';
    }
  }

  export function getIsDocumentHidden() {
    return !document[getBrowserDocumentHiddenProp()];
  }

export default function usePageVisibility() {
    const [isVisible, setIsVisible] = useState(getIsDocumentHidden());
    const onVisibilityChange = () => setIsVisible(getIsDocumentHidden());

    useEffect(() => {
      const visibilityChange = getBrowserVisibilityProp();

      document.addEventListener(visibilityChange, onVisibilityChange, false);

      return () => {
        document.removeEventListener(visibilityChange, onVisibilityChange);
      };
    });

    return isVisible;
  }
